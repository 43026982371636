/* Navbar Styling */
.custom-navbar {
    background-color: white; /* White background for the navbar */
    transition: box-shadow 0.3s ease, border-radius 0.3s ease;
    padding: 10px 20px;
    border-radius: 0; /* No rounded corners by default */
  }
  
  

/* Wrapper to manage layout of brand and toggle */
.navbar-content-wrapper {
  position: relative;
  width: 100%;
}

  /* Navbar when scrolled or when the hamburger is open */
  .scrolled-navbar {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle box shadow */
  }
  
  /* Custom Hamburger Menu */
  .custom-toggler {
    border: none !important;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Subtle box shadow */
    background-color: white;
    transition: box-shadow 0.3s ease;
  }

/* Navbar Brand Default State */
.navbar-brand {
    position: relative; /* Use relative positioning to avoid conflicts */
    left: 0;
    padding-left: 15px; /* Add padding to align the brand properly */
    transition: all 0.3s ease;
    z-index: 1050; /* Ensure it's on top of other elements */
  }
  
  
  
  .custom-toggler.collapsed {
    box-shadow: none; /* Remove shadow when collapsed */
  }
  
  .custom-toggler:not(.collapsed) {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5); /* Stronger box shadow when open */
  }
  
  /* Styling for the navbar-toggler-icon */
  .custom-toggler .navbar-toggler-icon {
    background-color: white;
    border-radius: 8px;
  }
  
  /* Links Styling */
  .custom-navbar .nav-link {
    color: black;
    margin-right: 20px; /* Default margin */
    transition: margin 0.3s ease; /* Smooth transition */
  }
  
  .custom-navbar .nav-link:hover {
    color: #666666; /* Highlight color on hover */
  }
  
  /* Container Padding to Avoid Overlap */
  #main-content {
    padding-top: 80px; /* Adjust padding to ensure content doesn't overlap with navbar */
  }

  /* Apply the animations */
.custom-navbar .navbar-collapse.collapsing .nav-link {
    animation: fadeOut 0.5s forwards; /* Fade out */
  }
  
  .custom-navbar .navbar-collapse.show .nav-link {
    animation: fadeIn 0.5s forwards; /* Fade in */
    opacity: 0; /* Start with zero opacity */
    animation-delay: 0.01s; /* Add a slight delay to match the collapse */
  }
  
  .custom-navbar .navbar-collapse.show {
    opacity: 1;
    margin-top: 20px;
  }

  .custom-navbar .navbar-collapse.collapsing {
    opacity: 0;
  }

  .custom-navbar .navbar-collapse {
    transition: opacity 0.5s ease;
  }
  /* Additional Spacing for Larger Screens */
@media (min-width: 992px) {
    .custom-navbar .nav-link {
      margin-right: 40px;/* Increase margin for larger viewports */
    }
  }

  /* Keyframes for Fading In and Out */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  /* Center the Brand when Navbar is Expanded (Mobile View) */
@media (max-width: 991px) { /* Target mobile and tablet viewports */
    .navbar-brand.brand-center {
      left: 46%; /* Move to center */
      transform: translateX(-50%); /* Center the brand horizontally */
      top: 10px; /* Adjust the top position if needed */
    }
  }
  