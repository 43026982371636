:root {
  --primary-color: white;
  --hover-color: #f0db4f;
  --background-color: white;
  --font-color: black;
}

html {
 scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--background-color);
  color: var(--font-color);
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Links */

a {
  color: var(--font-color);
  text-decoration: none;
}
a:hover {
  color: var(--font-color);
  text-decoration: underline;
}


h1, h2, h3 {
  font-family: 'Raleway', sans-serif;
  letter-spacing: 1px;
}

p {
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
}

/* Grid for Projects and Home Page */

.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.grid-item {
  background-color: silver;
  height: 20rem;
  width: 100%;
  overflow: hidden;
}

.project-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.chatbot-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #007bff;
  color: white;
  border-radius: 50%;
  padding: 15px;
  font-size: 18px;
  cursor: pointer;
  z-index: 1000;
}


/* Responsive Adjustments */
@media (max-width: 1000px) {
  #about-me {
    margin: 0 1rem;
  }

  .parent {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(15, 1fr);
  }

  .grid-item {
    width: 100%;
  }

  #main-content {
    padding-top: 150px; /* Make sure it clears the fixed navbar */
  }
}
